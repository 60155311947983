import { useState } from 'react';
import { Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
// import { collection, addDoc, getFirestore } from 'firebase/firestore';
// import { initializeApp } from 'firebase/app';

// import firebase from 'firebase/app';
// import 'firebase/firestore';

// // Initialize Firebase app
// const firebaseConfig = {
//   apiKey: "AIzaSyAUfFfbP6pzqjmIsAGrPJX_Qnn9zf-th08",
//   authDomain: "unieros-react.firebaseapp.com",
//   projectId: "unieros-react",
//   storageBucket: "unieros-react.appspot.com",
//   messagingSenderId: "1024289595776",
//   appId: "1:1024289595776:web:cf7c1961accafac203a165",
//   measurementId: "G-E6ZXBM626C"
// };

// firebase.initializeApp(firebaseConfig);

// // Access Firestore instance
// const firestore = getFirestore();

// // Now you can use the 'firestore' object to perform Firestore operations

// // Example of using 'firestore' in your code
// const handleSubmit = async (values, { setSubmitting }) => {
//   setSubmitting(false);

//   if (!currentUser) {
//     // Redirect to login
//     return;
//   }

//   try {
//     // Save volunteer data to Firestore
//     await firestore.collection('volunteers').add({
//       name: values.volunteerName,
//       email: values.volunteerEmail,
//       role: values.volunteerRole,
//       phone: values.volunteerPhone,
//       contact: values.volunteerEmergencyContact,
//       tshirt: values.volunteerTshirt,
//       description: values.volunteerDescription
//     });
//     console.log("Volunteer data saved successfully!");
//   } catch (error) {
//     console.error("Error saving volunteer data: ", error);
//   }
// };
const VolunteerRegistrationForm = () => {
  const initialValues = {
    volunteerName: '',
    volunteerEmail: '',
    volunteerRole: '',
    volunteerDescription: '',
    volunteerPhone: '',
    volunteerTshirt: '',
    volunteerEmergencyContact: '',
  };

  const validationSchema = Yup.object({
    volunteerName: Yup.string().required('Full name is required'),
    volunteerEmail: Yup.string().email('Invalid email address').required('Email is required'),
    volunteerRole: Yup.string().required('Volunteer role is required'),
    volunteerDescription: Yup.string().required('Description is required'),
    volunteerPhone: Yup.string().required('Phone number is required'),
    volunteerTshirt: Yup.string().required('T-shirt size is required'),
    volunteerEmergencyContact: Yup.string().required('Emergency contact name is required'),
  });

  // const [volunteerName, setVolunteerName] = useState('');
  const [volunteerEmail, setVolunteerEmail] = useState('');
  const [volunteerRole, setVolunteerRole] = useState('');
  const [volunteerDescription, setVolunteerDescription] = useState('');
  const [volunteerPhone, setVolunteerPhone] = useState('');
  const [volunteerTshirt, setVolunteerTshirt] = useState('');
  const [volunteerEmergencyContact, setVolunteerEmergencyContact] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(false);

    // if (!currentUser) {
    //   // Redirect to login
    //   return;
    // }

    if (!validateForm()) {
      return;
    }

    try {
      // Save volunteer data to Firestore
      // await addDoc(collection(db, 'volunteers'), {
      //   name: values.volunteerName,
      //   email: values.volunteerEmail,
      //   role: values.volunteerRole,
      //   phone: values.volunteerPhone,
      //   contact: values.volunteerEmergencyContact,
      //   tshirt: values.volunteerTshirt,
      //   description: values.volunteerDescription,
      // });
      console.log("Volunteer data saved successfully!");
    } catch (error) {
      console.error("Error saving volunteer data: ", error);
    }
  };

  const validateForm = () => {
    if (!initialValues.volunteerName.trim()) {
      alert('Please enter your name');
      return false;
    }

    if (!initialValues.volunteerEmail.trim()) {
      alert('Please enter your email');
      return false;
    }

    if (!validateEmail(initialValues.volunteerEmail)) {
      alert('Please enter a valid email');
      return false;
    }

    if (!initialValues.volunteerPhone.trim()) {
      alert('Please enter your phone number');
      return false;
    }

    const phoneRegex = /^\d{10}$/; // Matches a 10-digit phone number
    if (!phoneRegex.test(initialValues.volunteerPhone)) {
      alert('Please enter a valid phone number');
      return false;
    }

    return true;
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Matches email format
    return emailRegex.test(email);
  };

  return (
    <div>
      <h1 className="display-4">Volunteer Registration Form</h1>
      <div className="container volunteer-hero p-3">
            <div className="row">
               <div className="col-md-4">
        <img src="https://unieros.com/wp-content/uploads/2023/06/unieros_french_picnic-e1687413378344.jpeg" className="img-fluid" alt="volunteer unieros"/>
               </div>
               <div className="col-md-8">
    <p>Thank you for your interest in volunteering at the Unieros Caribbean Cultural Celebration. We appreciate your willingness to participate in our event, especially if you are new to multicultural experiences and Unieros. </p>
            <p>Volunteers play a vital role in making the festival a success, and we are excited to have your support! We have various volunteer opportunities available both before and during the festival. </p>
            <p>Before filling out the volunteer form, please click buttons to read the volunteer roles.
</p>
<h5 className="display-6"> Volunteer Roles</h5>
<p>
  <a className="btn btn-custom-primary" data-bs-toggle="collapse" href="#registrationCrew" role="button" aria-expanded="false" aria-controls="registrationCrew">Registration Crew</a>
  <button className="btn btn-custom-primary" type="button" data-bs-toggle="collapse" data-bs-target="#signageSquad" aria-expanded="false" aria-controls="signageSquad">Signage Squad</button>
  <button className="btn btn-custom-primary" type="button" data-bs-toggle="collapse" data-bs-target="#setupSquad" aria-expanded="false" aria-controls="setupSquad">Setup Crew</button>
</p>
<div className="row ">
<div className="col mb-3">
    <div className="collapse multi-collapse" id="registrationCrew">
      <div className="card card-body">
      Welcome and assist attendees who arrive without tickets. Our Registration Crew members have in-depth knowledge of the event's artists and activities, enabling them to answer questions and provide a seamless registration experience.

      </div>
    </div>
  </div>
  <div class="col">
    <div class="collapse multi-collapse" id="signageSquad">
      <div class="card card-body">
      Help create and display eye-catching signs, including window signs, yard signs, and flyers around town. The Signage Crew plays a crucial role in spreading the word about the festival and attracting more participants.
      </div>
    </div>
  </div>
  <div className="col mb-3">
    <div className="collapse multi-collapse" id="setupSquad">
      <div className="card card-body">
      Assist in setting up tents, tables, chairs, and more. Physical fitness and the ability to assemble items are important qualities for our Setup Squad members.
      </div>
    </div>
  </div>
</div>
              </div>
          </div>
    </div>
    {/*End Container */}
    <div className="container text-center">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
           {formik => (
          <form onSubmit={formik.handleSubmit} className="form-horizontal">
            <fieldset>
              <div className="row mb-3 input-group">
                <label htmlFor="volunteerName" className="col-form-label col-sm-4">Volunteer Full Name:</label>
                <div className="col-sm-6">
                  <input
                    type="text"
                    className="form-control"
                    id="volunteerName"
                    {...formik.getFieldProps('volunteerName')}
                    required
                    placeholder="Jane Doe"
                  />
                  <ErrorMessage name="volunteerName" component="div" />
                </div>
            
              </div>
              <div className="row mb-3 input-group">
                <label htmlFor="volunteerEmail" className="col-form-label col-sm-4">
                  Email:
                </label>
                <div className="col-sm-6 mb-3">
                  <input
                    type="email"
                    className="form-control"
                    id="volunteerEmail"
                    value={volunteerEmail}
                    onChange={(e) => setVolunteerEmail(e.target.value)}
                    required
                    placeholder="jdoe@example.com"
                  />
                  <ErrorMessage name="volunteerEmail" component="div" />
                </div>
              </div>
              <div className="row mb-3 input-group">
                <label htmlFor="volunteerRole" className="col-form-label col-sm-4">
                  Volunteer Role:
                </label>
                <div className="col-sm-6 mb-3">
                  <select
                    className="form-select"
                    aria-label="Default select example"
                    id="volunteerRole"
                    value={volunteerRole}
                    onChange={(e) => setVolunteerRole(e.target.value)}
                    required
                  >
                    <option value="">- Please Select -</option>
                    <option value="registration">Registration Crew</option>
                    <option value="signage">Signage Squad</option>
                    <option value="setup">Setup Squad</option>
                  </select>
                  <ErrorMessage name="volunteerRole" component="div" />
                </div>
              </div>
              <div className="input-group flex flex-wrap col-12 mt3">
                <label htmlFor="volunteerDescription" className="control-label col-md-4">
                  Tell us why you want to volunteer:
                </label>
                <div className="col-md-6 flex flex-wrap">
                  <textarea
                    className="form-control mb-3"
                    id="volunteerDescription"
                    name="volunteerDescription"
                    rows="5"
                    value={volunteerDescription}
                    onChange={(e) => setVolunteerDescription(e.target.value)}
                    spellCheck="false"
                  ></textarea>
                </div>
              </div>
              <div className="row mb-3 input-group">
                <label htmlFor="volunteerPhone" className="col-form-label col-sm-4">
                  Volunteer Phone Number
                </label>
                <div className="col-sm-6 mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="volunteerPhone"
                    value={volunteerPhone}
                    onChange={(e) => {
                      setVolunteerPhone(e.target.value);
                      setPhoneError('');
                    }}
                  />
                  {phoneError && <p className="error">{phoneError}</p>}
                </div>
              </div>
              <div className="row input-group">
                <label htmlFor="volunteerTshirt" className="col-form-label col-sm-4">
                  T-shirt Size:
                </label>
                <div className="col-sm-6 mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="volunteerTshirt"
                    value={volunteerTshirt}
                    onChange={(e) => setVolunteerTshirt(e.target.value)}
                    required
                    placeholder="Small, Medium, Large, etc."
                  />
                </div>
              </div>
              <div className="row mb-3 input-group">
                <label htmlFor="volunteerEmergencyContact" className="col-form-label col-sm-4">
                  Emergency Contact Name:
                </label>
                <div className="col-sm-6 mb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="volunteerEmergencyContact"
                    value={volunteerEmergencyContact}
                    onChange={(e) => setVolunteerEmergencyContact(e.target.value)}
                    required
                  />
                </div>
              </div>
              <div className="row mb-3 input-group">
                <button type="submit" className="btn btn-custom-primary">Submit</button>
              </div>
            </fieldset>
          </form>
        )}
      </Formik>
    </div>
    </div>
  );
};


export default VolunteerRegistrationForm;
