import React from 'react';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import NewsletterSignup from './NewsletterSignUp';
import Slider from 'react-slick';
import FlagBanner from './FlagBanner';
import Testimonials from './Testimonials';
import PastEvents from '../pages/PastEvents';
import SocialMediaLinks from './SocialMedia';
import DanceParty from '../assets/images/slider/unieros_dance_party.jpg';
import Paris from '../assets/images/slider/paris.jpeg';
import Kingston from '../assets/images/slider/kingston.jpg';
import Bahamas from '../assets/images/slider/bahamas.png';
import Carnival from '../assets/images/slider/carnival_kingston23.jpeg';
import ArtBasel from '../assets/images/slider/art_basel_miami23.jpeg';
import AfroAmFlag from '../assets/images/AfroAm.png';
import GuhHaad from '../assets/images/mens-t-shirt-regular-white-front.png';
import CraftJourney from '../assets/images/womens_craft_jamaica_journey_teeshirt.png';
import colors from '../theme';

import Community from '../pages/Community';
import '../custom.css';
import { useEffect } from 'react';
import { Modal } from 'react-bootstrap';



function Home() {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

//Create a carousel of multiple images the user can scroll through
const carouselImages = [
  {
    src: `${DanceParty}`,
    alt: 'Unieros multicultural dance event',
    caption: 'Unieros Event',
    category: 'Multicultural Events',
    videoUrl: 'https://www.youtube.com/embed/YsxVHJUaBuA',
    videoTitle: 'Unieros Multicultural Event',
  },
 
  {
    src: `${Paris}`,
    alt: 'Paris',
    caption: 'The Moulin Rouge, Paris',
    category: 'Tours & Travel',
    videoUrl: 'https://www.youtube.com/embed/Aqy-cZw6qmk?si=xi-7hNeP30aAOd3',
    videoTitle: 'Paris, France with Christine',
  },

  {
    src: `${Kingston}`,
    alt: 'Kingston',
    caption: 'Bob Marley Day, Kingston Jamaica',
    category: 'Festivals',
    videoUrl: 'https://www.youtube.com/embed/PTt5cXeP42A?si=jshmgy36EswKeCzo',
  },
  {
    src: `${Bahamas}`,
    alt: 'Bahamas',
    caption: 'Goombay Festival Little Bahamas, Miami',
    category: 'Festivals',
    videoUrl: 'https://www.youtube.com/embed/LpCEdyo4iHs?si=WXb0x1wbdMVT7ZIl',
  },
  {
    src: `${Carnival}`,
    alt: 'Jamaica Carnival',
    caption: 'Jamaica Carnival, Kingston',
    category: 'Ethnic Community Tours',
    videoTitle: 'Jamaica Carnival 2023',
    videoUrl: 'https://www.youtube.com/embed/PTt5cXeP42A?si=tCFeuULQkijxdz9i'
  },
  {
    src: `${ArtBasel}`,
    alt: 'Art Basel Miami 2023',
    caption: 'Art Basel Miami with Argentinian Artist Alfredo Sagatori',
    category: 'Arts & Culture',
    videoTitle: 'Art Basel Miami 2023',
    videoUrl: 'https://www.youtube.com/embed/ARMKaSG9550?si=WcWfGagyQRnVrMEC'

  },
];
const carouselStyle = {
  height: '300px', // Set fixed height for carousel
  marginBottom: '20px',
  
};
const [show, setShow] = useState(false);
const [videoUrl, setVideoUrl] = useState('');
const [videoTitle, setVideoTitle] = useState('');

const handleClose = () => setShow(false);
const handleShow = (url, title) => {
  setVideoUrl(url);
  setVideoTitle(title);
  setShow(true);
};

useEffect(() => {
  document.title = 'Unieros - One Love, Many Cultures';
}, []);

    return (

      <div className="homepage">
<div className="row">
    <section className="hero-section text-center p-5 homepage-background">
      <h1 className=" display welcome-text">Welcome to Unieros</h1>
      <p className="lead welcome-lead">One Love, Many Cultures</p>
      <div className="d-flex justify-content-center">
        <Link to="/events" className="btn m-2 hero-button">Explore</Link>
        <Link to="/tours" className="btn m-2 hero-button">Travel</Link>
        <Link to="https://tech.unieros.com" className="btn m-2 hero-button">Connect</Link>
      </div>
    </section>
  </div>
  <div className="row">

    <div className="row mb-3 p-3">
      <FlagBanner />
    </div>
  </div>
  <div className="row mb-3 p-3">
  <div className="col-12 home-explore">
  <h2 className="display"> Ready for your next adventure? </h2>
    <p>Our curated events and immersive travel experiences are designed to celebrate diversity and unite explorers. Elevate your business with our cutting-edge digital services, tailored with a global edge for growth in a connected world.
 
    </p>
    
  </div>
</div>
{ /* Upcoming event */ }
        <section>
        <div className="container">  
         <Community />

        <div className="row" >
 
          <div style={{ backgroundColor: colors.warmGold, color: colors.neutralGray }} className="mb-4">
          <p className="lead">Unieros Travel Vlog: Kingston to Ocho Rios, Jamaica</p>
          
          <p>Curious about Kingston the capital of Jamaica. Check out our Kingston Travel Tour.</p>
          <p><Link to="/tours" className="btn btn-unieros"> Book Tour </Link></p>

          </div>
        </div>

        <div className="row p-3">
        <div className="slider-container">
          <Slider {...settings}>
          <div className="col-md-12">
          <h2 className="display-6">Shop</h2>
          
            <img src={CraftJourney} alt="Unieros Tee Shirt" className="img-fluid" />
            <p class="fw-bold">Unieros T-Shirt 'Craft Your Journey' Jamaica Tour. Cotton T-shirt promoting Unieros 3-Day Kingston Tour. Sizes available for men and women. </p>
            <p><a href="https://unieros.threadless.com/designs/craft-your-journey" className="btn btn-unieros" role="button">Buy Now</a></p>
          </div>
          <div className="col-md-12">
            <img src={AfroAmFlag} alt="Afro Americano flag" className="img-fluid" />
            <p className="fw-bold">Celebrate African American heritage and lineage with this exclusive flag designed by Tribal Enterprises Africa</p>
            <p ><a href="https://buy.stripe.com/14k17gaUGexm4x2bJ3" className="btn btn-unieros" role="button">Buy Now</a></p>
          </div>
          <div className="col-md-12">
          <h2 className="display-6">Shop</h2>
            <img src={GuhHaad} alt="Unieros Tee Shirt" className="img-fluid" />
            <p class="fw-bold">Unieros T-Shirt 'Guh Haad and Done'. Cotton Jamaican Patois T-shirt. Sizes available for children, men and women. </p>
            <p><a href="https://unieros.threadless.com/designs/guh-haad-and-done-jamaica-patois-tshirt" className="btn btn-unieros" role="button">Buy Now</a></p>
          </div>
          </Slider>
         </div>
         {/* Social Media Links */}
          <SocialMediaLinks />
        </div>
        
         {/* Newsletter Signup */ }
          
         <div className="newsletter-signup">
        <NewsletterSignup />
      </div>  
    </div>

        </section>
    
           { /* Past Unieros Experiences */ }
      <div className="container-fluid">
  <div className="row video-highlights mt-5">
    <h2> Unieros Video Highlights</h2>
    <p>Join us on a journey of culture and innovation where every connection broadens your horizon. </p>
    {carouselImages.map((image, index) => (
      <div className="col-lg-4 col-md-6 col-sm-12 p-3" key={index}>
        <div className="card h-100 card-hover" onClick={() => handleShow(image.videoUrl)}>
          <img
            src={image.src}
            alt={image.alt}
            className="card-img-top"
            
          />
          <div className="card-body">
            <h5 className="card-category">{image.category}</h5>
            <h5 className="card-title">{image.caption}</h5>
          </div>
        </div>
      </div>
    ))}
    <Modal show={show} onHide={handleClose} className="brand-modal">
      <Modal.Header closeButton>
        <Modal.Title>{videoTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <iframe
          width="100%"
          height="315"
          src={videoUrl}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={handleClose}>
          Close
        </button>
      </Modal.Footer>
      </Modal>
  </div>
      </div>
  
      {/* Testimonials */}
      <section className="testimonials py-5">
        <div className="container">
          <Testimonials />
        </div>
      </section>

      {/* Past Events */}
      <section className="past-events py-5">
        <div className="container">
          <PastEvents />
        </div>
      </section>

      {/* Latest Blog Posts */}
      {/* <section className="latest-blog-posts py-5">
        <div className="container">
          <LatestBlogPosts />
        </div>
      </section> */}
      </div> 
      
    );
  };

  export default Home;
  