import React from 'react';
import './MembershipCard.css';

const MembershipCard = ({ memberName, uniqueIdentifier }) => {
  return (
    <div className="membership-card">
      <div className="card-header">Unieros Membership</div>
      <div className="card-body">
        <div className="member-name">Member Name: {memberName}</div>
        <div className="unique-id">Unique ID: {uniqueIdentifier}</div>
        <div className="instructions">
          <h5>Instructions for Partner Establishments:</h5>
          <ol>
            <li>Verify the member's name and unique ID on this digital card.</li>
            <li>Check if the unique ID is registered in our partner database.</li>
            <li>Provide the member with the applicable benefits and offers.</li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default MembershipCard;
