import React from 'react';
import SavannahUkraine from '../assets/images/events/ukraine_sav.jpg';
import SavJam24 from '../assets/images/events/unieros_jam_sav.jpg';
import DancesWorld from '../assets/images/events/unieros_dances_world.jpg';
document.title = 'Unieros - Archive of Multicultural Events';
const PastEvents = () => {
  
  const pastEventsData = [
    {
      title: 'Jamaica 60 Independence Celebration Savannah, Georgia',
      image: `${SavJam24}`, 
    },
    {
      title: 'Savannah Supports Ukraine, Georgia',  
      image: `${SavannahUkraine}`, 
    },
    {
      title: 'Unieros Dances Around the World, Chicago',
      image: `${DancesWorld}`,
    },
  ];

  return (
    <div className="past-events">
      <h2 className="text-center mb-4">Past Events Highlights</h2>
      <div className="row">
        {pastEventsData.map((event, index) => (
          <div key={index} className="col-md-4">
            <div className="card event-card">
              <img src={event.image} className="card-img-top" alt={event.title} />
              <div className="card-body">
                <h5 className="card-title event-title">{event.title}</h5>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PastEvents;
