import React from 'react';
import Header from '../components/Header';
import { Link } from 'react-router-dom';
import '../custom.css';
const Partnerships = ()  => {
  const videoId = 'B-bufubLFH0';
  document.title = 'Unieros - Partner with us';
    return (
      <div className="partnerships">
      <div className="container">
        <h1 className="display-1 page-title">Partner with Unieros</h1>
        <p className="lead">
          Unieros events are an excellent opportunity for businesses to showcase their brands and connect with a diverse and engaged audience. 
        </p>
        <div className="embed-responsive embed-responsive-16by9">
        <iframe
          className="embed-responsive-item"
          src={`https://www.youtube.com/embed/${videoId}`}
          title="Latest Video from Unieros community"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <div>
      <h3 className="display-6">Become an event Sponsor</h3>
        <p>
          Our partnership program offers a variety of options to help you reach your target audience and maximize brand exposure.</p>
          
          <ul className="sponsor-options">
            <li>Website
            <p>Your logo will be displayed on our website.</p>

            </li>
            <li>Event signage
            <p>Your logo will be displayed on all event signage.</p>

            </li>
            <li>Social media
            <p>We will promote your brand on our social media channels.</p>

            </li>
            <li>Email marketing
            <p>Your logo will be included in our email marketing campaigns.</p>

            </li>
            <li>Custom marketing 
            <p>We will work with you to create a custom marketing plan.</p>

            </li>
            </ul>
            <div>
        
        <img src="https://unieros.com/wp/wp-content/uploads/2016/06/SonTrio.jpg" className="img-fluid p-3" alt="Unieros Roots Rock Reggae concert in Chicago"/>
        </div>
        <p>Contact us for our sponsorship packages and choose the one that best fits your needs.</p>
    
        <a href="https://forms.gle/VTpDSCQfckHg1M4h9" className="btn btn-unieros m-3">Request Sponsor Package</a>
           <p>We work closely with our partners to create a tailored plan that meets their unique needs and objectives.
        </p>
  
    
        </div>
        <div>
  
      
       
       </div>
       {/* <div>
       <figure class="text-center">
  <blockquote class="blockquote red-quote">
    <p className="display-6">We are more alike than unalike.</p>
  </blockquote>
  <figcaption class="blockquote-footer">
    <cite title="Source Title">Maya Angelou</cite>
  </figcaption>
</figure>
       </div> */}
       
      </div>
      </div>
    );
  }

  export default Partnerships;