import React from 'react';
import { Card } from  'react-bootstrap';
import { Link } from 'react-router-dom';
// import firebaseIntances from "../firebaseConfig";
import Tours from './Tours';

import jamaicaEvent from '../assets/images/Jamaica61_event.jpg';



// const { db } = firebaseIntances;
function Events() {
  //Connect to firebase
  // useEffect(() => {
  //   const db = db.firestore();
  //   db.collection('events')
  //     .orderBy('date', 'asc')
  //     .get()
  //     .then((querySnapshot) => {
  // //       const data = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
  // //       setEvents(data);
  // //     })
  // //     .catch((error) => {
  // //       console.log('Error getting events: ', error);
  // //     });
  // // }, []);

  // // const handleSearchChange = (event) => {
  // //   setSearchTerm(event.target.value);
  // // };

  // const filteredEvents = events.filter((event) => {
  //   return (
  //     event.category.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     event.location.toLowerCase().includes(searchTerm.toLowerCase()) ||
  //     event.title.toLowerCase().includes(searchTerm.toLowerCase())
  //   );
  // });
  document.title = 'Unieros Multicultural Events';

    return (
      
      <section class="py-5 text-center container">   
        <div class="row py-lg-5">
          <div class="col-lg-6 col-md-8 mx-auto">
          <h1 class="display-1 page-title">Events</h1>
            <p class="lead text-body-secondary">
              <p>Explore and connect with different cultures through Unieros events.
                From food tours to music festivals, you'll love the immersive and welcoming atmosphere.
              </p>
            </p>
            <p className="mb-2">
              {/* <a href="#" class="btn btn-unieros my-2">Sign up</a> */}
            </p>
          </div>
        </div>
     
      
      <div className="container my-5">
      <div className="row mt-4">
       
      <h2>Latest event</h2>
        <div className="col-sm-12 mb-4">
          <Card>
            <Card.Img variant="top" src={jamaicaEvent} />
            <Card.Body>
              <Card.Title >Tings A Gwaan: Celebration of Jamaican & Caribbean Culture</Card.Title>
              <Card.Text>
                
              </Card.Text>
              <Link to={`/global/event-review-tings-a-gwaan-a-celebration-of-jamaican-caribbean-culture`} className="btn btn-unieros">View photos</Link>
            
            </Card.Body>
          </Card>
        </div>
      </div>
{/* /      <div className="row mt-4">
      <h2>Upcoming events</h2>
        <div className="col-sm-12 mb-4">
          <Card className="event-list"> 
            <Card.Body>
              <Card.Title className="peach"> <i class="bi bi-music-note-beamed"></i> Music & Art Events</Card.Title>  
              <Card.Text>
                Immerse yourself in a world of music and art with our upcoming events.
              </Card.Text>
              <Link to="/eventlist" className="btn btn-unieros">View Events</Link>
            </Card.Body>
          </Card>
        </div>
</div> */}
      </div>
      <div className="container my-5">
      <h2>Upcoming events</h2>
      <Tours />
      </div>
</section>
    );
  }

  export default Events;