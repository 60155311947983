import React from "react";


const Tech = () => {   
  return (
    <div>
      <header className="hero-tech">
        <div className="col-md-6 col-sm-12">
        <img className="img-fluid" src="https://i0.wp.com/unieros.com/wp/wp-content/uploads/2024/02/software_developer.webp?w=1024&ssl=1b" alt="Unieros Technical Services" className="hero-img" />
        </div>
        <div className="col-md-6 col-sm-12">
         <h1 className="hero-title"> Beyond events, we offer digital services to support your online presence.</h1>
            <h3>Whether you’re a small business, a creative individual, or an organization, we have the solutions to ensure your online journey.</h3>
        </div>
      
      </header>
      <div className="row tech-services">
        <h2>Our Digital Services</h2>
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
            <i class="bi bi-globe"></i>
              <h5 className="card-title">Web Support</h5>
              <p className="card-text">
              Our team is equipped to handle technical troubleshooting. Focus on your core business while we take care of the technical aspects to ensure your website remains optimized and up-to-date.
              </p>
              {/* Stripe Payment Button */}
             <a href="https://buy.stripe.com/3cs17g5Amcpe5B6fZ6" className="btn btn-unieros">
                Purchase
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
            <i className="bi bi-laptop"></i> 
              <h5 className="card-title">Web Development</h5>
              <p className="card-text">
              Have an existing website that needs maintenance? Our Website Optimization and Performance Enhancement Package is $300 one-time fee. Additional, ongoing support at $50/hour.

              </p>

              <a href="https://buy.stripe.com/fZe6rAe6SblaaVq4gj" className="btn btn-unieros">
                Purchase
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="card">
            <div className="card-body">
            <i class="bi bi-camera-reels-fill"></i>
              <h5 className="card-title">Video Editing</h5>
              <p className="card-text">
                From Video Optimization for Social Media Platforms to Video Editing and Production, we offer a range of video services to help you create engaging content. $50/hour with 2 hour mininum.
              </p>
              <a href="https://buy.stripe.com/9AQcPY8My88Y9Rm3cm" className="btn btn-unieros">
                Purchase
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="row tech-desc">
        <h2>Tailored Solutions</h2>
        <p>Please note that the above prices are indicative and may vary based on the specific needs and requirements of your project. 
</p>
<p>
At Unieros Technical Services, we combine technical expertise, creativity, and attention to detail to provide you with top-notch solutions that amplify your online presence. Our team is dedicated to delivering results and exceeding your expectations. Let us take care of the technical aspects while you focus on what you do best.
</p>
<p>
Contact us today to discuss your technical needs and explore how Unieros can empower your digital journey.
</p>
</div>
    </div>
  );
};

export default Tech;
