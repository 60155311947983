import React, { createContext, useContext, useEffect, useState } from "react";
import { auth } from "./firebaseConfig";
import {
  signOut,
  onAuthStateChanged,
  sendSignInLinkToEmail as firebaseSendSignInLinkToEmail,
} from "firebase/auth";

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const sendSignInLinkToEmail = async (email) => {
    try {
      await firebaseSendSignInLinkToEmail(auth, email, {
        url: "https://unieros.com/profile",
        handleCodeInApp: true,
        // dynamicLinkDomain: "your-dynamic-link-domain.com", // Optional: Your custom dynamic link domain
      });
      window.localStorage.setItem("emailForSignIn", email);
    } catch (error) {
      console.error("Error sending sign-in link:", error.code, error.message);
    }
  };

  const logout = () => {
    return signOut(auth);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      try {
        console.log("Auth state changed. User:", user);
        setCurrentUser(user);
        setLoading(false);
      } catch (error) {
        console.error("Error setting current user:", error);
      }
    });

    // Handle email link sign-in
    if (window.location.href.includes("complete-signin")) {
      const email = window.localStorage.getItem("emailForSignIn");
      if (email) {
        auth
          .signInWithEmailLink(email, window.location.href)
          .then((result) => {
            // User signed in successfully
            console.log(result);
            window.localStorage.removeItem("emailForSignIn"); // Clear the email from local storage
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }

    return () => {
      unsubscribe();
    };
  }, []);

  const value = {
    currentUser,
    logout,
    sendSignInLinkToEmail,
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
