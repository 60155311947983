import React, {useEffect} from 'react';
import { BrowserRouter as Router, Route, Switch, Routes , useNavigate, useLocation} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import LoginForm from './pages/LoginForm';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Events from './components/Events';
import BookTours from './components/Tours';
import TourBookingForm from './components/BookingForm';
import About from './pages/About';
import Contact from './pages/Contact';
import Community from './pages/Community';
import Blog from './pages/Blog';
import Partnerships from './pages/Partnerships';
import Profile from './pages/Profile';
import EventList from './pages/EventList';
// import VendorRegistrationForm from './pages/VendorRegistration';
import VolunteerRegistrationForm from './pages/VolunteerRegistration';
import CompleteSignIn from './pages/CompleteSignin';
import AfroHall from './pages/AfroHall';
import Post from './components/Post';
import EventArchive from './pages/EventArchive';
import News  from './components/News';
import Tech from './pages/Tech';
//import logo from './logo.svg';
import ReactGA from 'react-ga4';
import './App.css';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

//Initialize google analytics
ReactGA.initialize('G-KP8M33W4RQ');



function App() {

  return (
    
    <Router>
      <AnalyticsComponent />
    <div className="App">
      <Header /> 
  
      <Routes>
        <Route path="/" exact Component={Home} />
        <Route path="/events" exact Component={Events} />
        <Route path="/about" exact Component={About} />
        <Route  path="/blog" exact Component={Blog} />
        {/* <Route  path="/community" exact Component={Community} /> */}
        <Route  path="/partnerships" exact Component={Partnerships} />
        <Route path="/contact" exact Component={Contact} /> 
        <Route path="/login" exact Component={LoginForm} />
        <Route path="/profile" exact Component={Profile} />
        {/* <Route path="/eventlist" exact Component={EventList} /> */}
        {/* <Route path="/vendor-registration" exact Component={VendorRegistrationForm} /> */}
        <Route path="/volunteer" exact Component={VolunteerRegistrationForm} />
        <Route path="/tours" exact Component={BookTours} />
        <Route path="/book-tours" exact Component={TourBookingForm} />
        <Route path="/complete-signin" exact Component={CompleteSignIn} />
        {/* <Route path="/afrohall" exact Component={AfroHall} /> */}
        <Route path="/post/:slug" element={<Post />} />
        <Route path="/event-archive" exact Component={EventArchive} />
        <Route path="/news" exact Component={News} />
        <Route path="/tech" exact Component={Tech} />
      </Routes>
  
     
    </div>
   
    <Footer />
    </Router>
   
  );
}
function AnalyticsComponent() {
  const location = useLocation();
  
  useEffect(() => {
    // Initialize GA4 with your measurement ID
    ReactGA.initialize([
      {
        trackingId: "G-KP8M33W4RQ",
      },
    ]);

    // Send pageview with a custom path
    ReactGA.send({ hitType: "pageview", page: location.pathname, title: document.title });
  
  }, [location]);
  return null;
}

export default App;
