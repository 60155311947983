import React, { useState } from 'react';
import Modal from 'react-modal';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import GroupMural from '../assets/images/group_mural.png';
import Unieros2006 from '../assets/images/unieros_2006.png';
import UnierosLaunch from '../assets/images/unieros_launch.JPG';
import UnierosWorldCup from '../assets/images/unieros_2010_worldcup.jpg';
import UnierosNewYork from '../assets/images/unieros_newyork.jpg';
import UnierosPicnic from '../assets/images/unieros_picnic.jpg';
import UnierosPodcast from '../assets/images/Unieros_podcast_cover.png';
import UnierosSavannah from '../assets/images/unieros_event_volunteer.jpeg';

const events = [
    {
      date: '2004',
      title: 'Unieros Founded',
      description: 'Christine Brown, web developer and entrepreneur created Unieros website in 2004 to celebrate culture and diversity in Chicago. She coined the word Unieros from two words:  "Universal" and Eros, the Greek god of love, to express the idea of one love.',
     videoId: 'lTKnYHbBQmk',
      thumbnail: Unieros2006
    },
    {
      date: '2009',
      title: ' Unieros Events Launch',
      description: 'Expanded to host multicultural events in Chicago with the first event at an Argentinian restaurant with world music, art from a Brazilian photographer, and food',
    //    videoId: 'ANOTHER_VIDEO_ID',
      thumbnail: UnierosLaunch
    
    },
    {
      date: '2010',
      title: 'Unieros Multicultural Events',
      description: 'Unieros continued its events with several events celebrating world cultures including:  a Dances Around the World event and a world cup viewing parting in Soldier Field Chicago, along with French in Chicago along with a party featuring South African mascot Zakumi.',
      videoId: 'jToDGBZBa1U',
      thumbnail: UnierosWorldCup
    },
    {
      date: '2011',
      title: 'Unieros Travel Launch',
      description: 'Unieros group travel including camping with friends, skiing, and later expanded to travel to cities like New York, Mackinac Island, and more',
      videoId: 'SSNV31Wb_oc',
      thumbnail: UnierosNewYork

    },
    {
      date: '2010-2016',
      title: 'Unieros Signature Events',
      description: 'Unieros became known for its cultural events including themed dinner parties, picnics, dance, and art events. Each event was designed to bring people together to celebrate culture and diversity.',
      videoId: 'YsxVHJUaBuA',
      thumbnail: UnierosPicnic
    },
    {
      date: '2014',
      title: 'Unieros Podcast',
      description: 
      <><p>Unieros launched a podcast called Multicultural Talks to share stories of people from different cultures and backgrounds. The podcast was hosted by Christine Brown and featured guests from around the world. <a href='https://podcasters.spotify.com/pod/show/unieros'>Listen to the lastest podcast episodes</a></p>
      </>,
     videoId: 'ab6_kFVY81U',
     thumbnail: UnierosPodcast
      
    },
    {
      date: '2021',
      title: 'Unieros LLC',
      description: 'Unieros official company established to incorporate events, travel and technology with first events in Savannah, Georgia.',
      videoId: 'B-bufubLFH0',
      thumbnail: UnierosSavannah
    },
    {
      date: '2023',
      title: 'Unieros Tours',
      description: 'Unieros establishes new to Kingston, Jamaica where visitors will enjoy 3-day experience of Jamaican music, food and art.',
      videoId: 'zIubIYESXeo',
      thumbnail: GroupMural
    },
    // Add more events as needed
  ];
  
  const TimelineComponent = () => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalVideoId, setModalVideoId] = useState('');
  
    const openModal = (videoId) => {
      setModalVideoId(videoId);
      setModalIsOpen(true);
    };
  
    const closeModal = () => {
      setModalIsOpen(false);
      setModalVideoId('');
    };

  return (
    
    <div>
        <div className="mb-4">
       
       
        <h2>Our Story</h2>
        </div>
      <VerticalTimeline>
        {events.map((event, index) => (
          <VerticalTimelineElement
            key={index}
            date={<strong><span className="lead">{event.date}</span></strong>}
            iconStyle={{ background: 'rgb(255, 0, 0)', color: '#fff' }}
          >
            <h3 className="vertical-timeline-element-title">{event.title}</h3>
            <p>{event.description}</p>
            <img
              src={event.thumbnail}
              alt={`${event.title} thumbnail`}
              className="thumbnail-preview py-3"
              onClick={() => openModal(event.videoId)}
            />
            {event.videoId && (
            <button onClick={() => openModal(event.videoId)} className="btn btn-primary">
              Watch Video
            </button>
            )}
          </VerticalTimelineElement>
        ))}
      </VerticalTimeline>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Event Video"
      >
        <button onClick={closeModal} className="close-modal">X</button>
        <iframe
          width="100%"
          height="315"
          className="embed-responsive-item"
          src={`https://www.youtube.com/embed/${modalVideoId}`}
          title="Event Video"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </Modal>
      
    </div>
  );
};

export default TimelineComponent;
