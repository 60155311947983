import React from 'react';
import { useAuth } from '../AuthContext';
import LoginForm from './LoginForm';
import ProfileForm from '../components/ProfileForm';

export default function Profile() {
  const { currentUser, logout } = useAuth();

  if (currentUser) {
    return (
      <div>
        <p>Logged in as {currentUser.email}</p>
        <ProfileForm />
        <button onClick={logout}>Log Out</button>
      </div>
    );
  } else {
    return (
      <div>
        <p>You are not logged in.</p>
        <LoginForm />
      </div>
    );
  }
}