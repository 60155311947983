import React, { useRef } from "react";
import { useAuth } from "../AuthContext";
import { getFirestore, doc, setDoc } from "firebase/firestore";
import MembershipCard from "../components/MembershipCard";
import { customAlphabet } from "nanoid";
import TourBookingForm from "./BookingForm";
import {Link } from 'react-router-dom';

const ProfileForm = ( {onLoggedIn}) => {
const nanoid = customAlphabet("0123456789UNI", 7);
const uniqueIdentifer = nanoid();

     //Logout
     const handleLogout = async () => {
      try {
        await logout();
        // setIsLoggedIn(false);
      } catch (error) {
        console.error('Failed to log out:', error);
      }
    };
  const { currentUser } = useAuth();
  const { logout } = useAuth();
  const nameRef = useRef();
  const interestRef = useRef(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

     // Get all checked checkboxes and store their values in an array
  const selectedInterests = Array.from(interestRef.current.querySelectorAll('input[type="checkbox"]:checked')).map(
    (checkbox) => checkbox.value
  );

  const db = getFirestore();
  const userDocRef = doc(db, "users", currentUser.uid);

  const preferences = {
    fullName: nameRef.current.value,
    email: currentUser.email, // Save the user's email
    culturalInterest: selectedInterests, // Save the array of interests
  };

  await setDoc(userDocRef, { preferences }, { merge: true });

  alert("Preferences saved!");
};

  return (
    <div className="container profile-desc">
      <p>The profile page allows you to personalize your experience on our platform.  Additionally, this profile page serves as a central hub for viewing your unique membership details.</p>
      <form onSubmit={handleSubmit} className="mt-3">
        <div className="form-group">
          <label htmlFor="name">First and Last Name:</label>
          <input
            type="text"
            id="name"
            className="form-control"
            name="name"
            ref={nameRef}
            required
          />
        </div>
        <div className="form-group" ref={interestRef}>
          <label>Cultural Interests:</label>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="music"
              name="culturalInterest"
              value="Music"
              ref={interestRef}
            />
            <label className="form-check-label" htmlFor="music">
              Music
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="food"
              name="culturalInterest"
              value="Food"
              ref={interestRef}
            />
            <label className="form-check-label" htmlFor="food">
              Food
            </label>
          </div>
          <div className="form-check">
            <input
              className="form-check-input"
              type="checkbox"
              id="internationalTravel"
              name="culturalInterest"
              value="International Travel"
              ref={interestRef}
            />
            <label className="form-check-label" htmlFor="internationalTravel">
              International Travel
            </label>
          </div>
        </div>

        <button type="submit" className="btn btn-unieros">
          Save Preferences
        </button>
        {currentUser && (
        <button className="mb-3" onClick={handleLogout}>Logout</button>
      )}
      </form>
      <div className="pt-4">
        <h3 className="display6"> Book Tour </h3>
        <TourBookingForm />
      </div>
      <div className="pt-4">
      <MembershipCard
        memberName={currentUser.nameRef}
        uniqueIdentifer={uniqueIdentifer}
      />
      </div>
    </div>
  );
};

export default ProfileForm;
