import React from 'react';
import TimelineComponent from '../components/Timeline';
import 'react-vertical-timeline-component/style.min.css';
import EventArchive from './EventArchive';
import { Link } from 'react-router-dom';


function About() {
  document.title = 'Unieros - About Our Mission to Celebrate Diversity of Cultures';
  const videoId = 'YsxVHJUaBuA';
    return (
      <div className="container mx-auto px-4">

        <h1 className="display-1 page-title">About Unieros</h1>
        <div className="row">
        <p className="lead"> Unieros is an event company focused on celebrating our world's diverse cultures. We are passionate about uniting people of different backgrounds and helping them learn about each other's traditions. Our events include cultural dinners, art shows, world music concerts, charity events, and more. We believe that by sharing our cultures, we can build a more peaceful and loving world.</p>
        </div>
      <TimelineComponent />
    
{/* <div className='testimonial d-block w-100'><p className="testimonial-content">Unieros leverages the latest technologies to ensure that the cultural dialogue grows richer.</p><span className="small"><a href="https://chicago.suntimes.com/news/2012/4/13/18534100/ease-of-making-connections">Chicago Sun-Times </a></span></div>
<div className="about-section">
  <h2 className="text-xl font-bold">Our Story</h2>
  <p>Unieros, founder Christine Brown Clayton (nee Christine Brown), established Unieros in 2004 as a website to celebrate culture and diversity. The sole purpose was to connect people across cultures in social events. Since then Unieros, has expanded to multicultural events in cities like Chicago and Savannah, Georgia.</p>
<p>In 2022 Unieros brought the first <a className ="text-blue-600 visited:text-purple-600"href="https://www.wtoc.com/2022/08/07/jamaican-independence-celebrated-savannah/">Jamaican Independence celebration to Savannah, Georgia </a>to connect the growing Jamaican diaspora in South East Georgia. </p>
</div> */}

<div className="about-section p-4">

  <div className="mb-4">
<EventArchive />

<div>
<img sr="" className="img-fluid"/>
</div>
<div>
<p>We are passionate about uniting people of different backgrounds and helping them learn about each other's traditions. Our events include tasty food tours, ethnic dining experiences, colorful multicultural festivals, and exciting world music shows. </p>
</div>


<p><Link to="/contact" className="btn btn-unieros">Join us</Link></p>

</div>
</div>
</div>

    );
  }

  export default About;