import React, { useRef, useState } from "react";
import { sendSignInLinkToEmail } from "firebase/auth";

const Login = () => {
  const emailRef = useRef();
  // const passwordRef = useRef();
  // const { login, signup, sendVerificationEmail, currentUser, resendVerificationEmail, sendResetPasswordEmail } = useAuth();
  // const [isSignUp, setIsSignUp] = useState(false);
  const [error, setError] = useState("");
  // const [resetPassword, setResetPassword] = useState(false);
  // const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

     const email = emailRef.current.value;
     const actionCodeSettings = {
      url: "https://wwww.unieros.com/profile",
      handleCodeInApp:true,
     };

 sendSignInLinkToEmail(email, actionCodeSettings)
 .then( () => {
  setError("Sign-in link sent. Please check your email");
 })
 .catch((error) => {
  setError("Failed to send sign-in link.");
 
 });
  
  }

  return (
    <div className="container">
      {error && <p>{error}</p>}
      <h1 className="display-1 page-title">Login</h1>
      <p>In order to comment or book events and tours with us must login or have an account with Unieros.</p>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="email">Email:</label>
          <input
            type="email"
            id="email"
            className="form-control mb-3"
            name="email"
            ref={emailRef}
            required
          />
        </div>
        
          <button type="submit" className="btn btn-unieros mb-3">
            Send Email Link to Sign In
          </button>
       
      </form>
     
    </div>
  );
};

export default Login;
