import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import {
    FacebookShareButton,
    TwitterShareButton,
    LinkedinShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinIcon
  } from 'react-share';
  

const Post = () => {
    const [post, setPost] = useState(null);
    const { slug } = useParams();

    useEffect( () => {
        const fetchPost = async () => {
            const response = await axios.get(`https://unieros.com/global/wp-json/wp/v2/posts?slug=${slug}&_embed=tags`);
            setPost(response.data[0]);
        };
        fetchPost();
    }, [slug]);
    useEffect(() => {
        if (post) {
            const postContent = document.querySelector('.post-content');
            if (postContent) {
                // Add Bootstrap classes to WordPress content
                const buttons = postContent.querySelectorAll('button');
                buttons.forEach(button => button.classList.add('btn', 'btn-unieros', 'm-1'));

                // Add Bootstrap classes to WordPress images
                const images = postContent.querySelectorAll('img');
                images.forEach(image => image.classList.add('img-fluid'));

                // Add Bootstrap classes to WordPress lists
                const lists = postContent.querySelectorAll('ul, ol');
                lists.forEach(list => list.classList.add('list-group', 'list-group-flush'));

                // Add Bootstrap classes to WordPress tables
                const tables = postContent.querySelectorAll('table');
                tables.forEach(table => table.classList.add('table'));

                // Add Bootstrap classes to WordPress links
                const links = postContent.querySelectorAll('a');
                links.forEach(link => link.classList.add('text-unieros'));

                // Add Bootstrap classes to WordPress text
                const paragraphs = postContent.querySelectorAll('p');
                paragraphs.forEach(paragraph => paragraph.classList.add('text-body'));
            }
        }
    }, [post]);

    if (!post) return 'Loading...';
const shareUrl = window.location.href;
  const title = post.title.rendered;
  const featuredImage = post._embedded && post._embedded['wp:featuredmedia'] ? post._embedded['wp:featuredmedia'][0].source_url : '';

    return (
        <div className="container">
            <h1>{post.title.rendered}</h1>
            {featuredImage && <img src={featuredImage} alt={title} className="img-fluid mb-3" />}
            <div className="row post-content" dangerouslySetInnerHTML={{ __html: post.content.rendered }} />
      <div className="tags">
        {post._embedded && post._embedded['wp:term'] && post._embedded['wp:term'][1].map((tag) => (
          <span key={tag.id} className="badge badge-danger">{tag.name}</span>
        ))}
      </div>
      <div className="social-share">
        <FacebookShareButton url={shareUrl} quote={title} className="mr-2">
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <TwitterShareButton url={shareUrl} title={title} className="mr-2">
          <TwitterIcon size={32} round />
        </TwitterShareButton>
        <LinkedinShareButton url={shareUrl} title={title} className="mr-2">
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
      </div>
    </div>
  );
};

export default Post;