import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookF, faYoutube, faTwitter } from '@fortawesome/free-brands-svg-icons';


function Footer() {
    return (
    
        <footer className="bg-footer text-yellow py-4">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <h5>© Unieros LLC 2004 - 2024 </h5>
            <p>Connecting cultures through events and experiences</p>
          </div>

          <div className="col-md-6 d-flex justify-content-md-end align-items-center">
            <a href="https://www.instagram.com/unieros/" target="_blank" rel="noreferrer" className="text-white mx-2">
              <FontAwesomeIcon icon={faInstagram} size="2x" />
            </a>
            <a href="https://www.facebook.com/unieros/" target="_blank" rel="noreferrer" className="text-white mx-2">
              <FontAwesomeIcon icon={faFacebookF} size="2x" />
            </a>
            <a href="https://www.youtube.com/@Unieros" target="_blank" rel="noreferrer" className="text-white mx-2">
              <FontAwesomeIcon icon={faYoutube} size="2x" />
            </a>
            <a href="https://twitter.com/unieros" target="_blank" rel="noreferrer" className="text-white mx-2">
              <FontAwesomeIcon icon={faTwitter} size="2x" />
            </a>
          </div>
        </div>
      </div>
    </footer>
     
     
    );
  }

  export default Footer;