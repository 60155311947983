import React, { useState, useEffect } from 'react';

const NewsletterSignup = () => {
  const [email, setEmail] = useState('');
  const [submitted, setSubmitted] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [isFormVisible, setIsFormVisible] = useState(true);

  const MAILCHIMP_FORM_ACTION_URL = 'https://unieros.us10.list-manage.com/subscribe/post?u=50902bc814a0e8a5264160010&amp;id=4f21c2781a&amp;f_id=00e4c2e5f0';

  useEffect(() => {
    // Load the jQuery script
    const jQueryScript = document.createElement('script');
    jQueryScript.src = 'https://code.jquery.com/jquery-3.6.0.min.js';
    jQueryScript.type = 'text/javascript';
    jQueryScript.async = true;
    document.body.appendChild(jQueryScript);
  
    // Load the Mailchimp validation script after jQuery is loaded
    jQueryScript.onload = () => {
      const mailchimpScript = document.createElement('script');
      mailchimpScript.src = '//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js';
      mailchimpScript.type = 'text/javascript';
      mailchimpScript.async = true;
      document.body.appendChild(mailchimpScript);
  
      // Initialize the Mailchimp form
      mailchimpScript.onload = () => {
        (function($) {
          window.fnames = new Array();
          window.ftypes = new Array();
          window.fnames[1] = 'FNAME';
          window.ftypes[1] = 'text';
          window.fnames[0] = 'EMAIL';
          window.ftypes[0] = 'email';
        })(window.jQuery);
        var $mcj = window.jQuery.noConflict(true);
      };
    };
  
    // Cleanup scripts when component unmounts
    return () => {
      document.body.removeChild(jQueryScript);
      const mailchimpScript = document.querySelector('script[src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"]');
      if (mailchimpScript) {
        document.body.removeChild(mailchimpScript);
      }
    };
  }, []);
  const handleSubmit = (e) => {
    e.preventDefault();
    if (email && firstName) {
      setSubmitted(true);
    }
  };



  return (
    <div id="mc_embed_signup" className="newsletter-signup container">
      <h3 className="display">Get Your Free Travel Guide!</h3>
      <p>Sign up for our newsletter and receive a complimentary travel guide to kickstart your next adventure.</p>
      {isFormVisible && (
      <form
        action={MAILCHIMP_FORM_ACTION_URL}
        method="post"
        id="mc-embedded-subscribe-form"
        name="mc-embedded-subscribe-form"
        className="validate"
        target="_blank"
        onSubmit={handleSubmit}
        noValidate
      >
        <div id="mc_embed_signup_scroll">
          <div className="indicates-required form-group">
            <small className="text-muted">
              <span className="asterisk">*</span> indicates required
            </small>
          </div>
          <div className="mc-field-group form-group">
            <label htmlFor="mce-FNAME" className="form-label">First Name </label>
            <input
              type="text"
              name="FNAME"
              className="text form-control"
              id="mce-FNAME"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
          <div className="mc-field-group form-group">
            <label htmlFor="mce-EMAIL">
              Email Address <span className="asterisk">*</span>
            </label>
            <input
              type="email"
              name="EMAIL"
              className="required email form-control"
              id="mce-EMAIL"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div id="mce-responses" className="clear foot">
            <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
            <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
          </div>
          <div aria-hidden="true" style={{ position: 'absolute', left: '-5000px' }}>
            <input
              type="text"
              name="b_50902bc814a0e8a5264160010_4f21c2781a"
              tabIndex="-1"
              value=""
              readOnly
            />
          </div>
          <div className="optionalParent">
            <div className="clear foot">
              <input
                type="submit"
                name="subscribe"
                id="mc-embedded-subscribe"
                className="btn btn-unieros"
                value="Subscribe"
              />
              {/* <p style={{ margin: '0px auto' }}>
                <a href="http://eepurl.com/iXqTN6" title="Mailchimp - email marketing made easy and fun">
                  <span
                    style={{
                      display: 'inline-block',
                      backgroundColor: 'transparent',
                      borderRadius: '4px',
                    }}
                  >
                    <img
                      className="refferal_badge"
                      src="https://digitalasset.intuit.com/render/content/dam/intuit/mc-fe/en_us/images/intuit-mc-rewards-text-dark.svg"
                      alt="Intuit Mailchimp"
                      style={{ width: '220px', height: '40px', display: 'flex', padding: '2px 0px', justifyContent: 'center', alignItems: 'center' }}
                    />
                  </span>
                </a>
              </p> */}
            </div>
          </div>
        </div>
      </form>
      )}
       <div id="mce-responses" className="clear">
        <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
        <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
      </div>
    </div>
  );
};

export default NewsletterSignup;