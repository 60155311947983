// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth, sendSignInLinkToEmail } from 'firebase/auth';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAUfFfbP6pzqjmIsAGrPJX_Qnn9zf-th08",
  authDomain: "unieros-react.firebaseapp.com",
  projectId: "unieros-react",
  storageBucket: "unieros-react.appspot.com",
  messagingSenderId: "1024289595776",
  appId: "1:1024289595776:web:cf7c1961accafac203a165",
  measurementId: "G-E6ZXBM626C"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);

// Example function to send sign-in link to email
// const sendSignInLinkToEmail = async (email) => {
//   const actionCodeSettings = {
//     url: 'https://localhost/profile', // Replace with your redirect URL
//     handleCodeInApp: true,
//   };

//   try {
//     await sendSignInLinkToEmail(auth, email, actionCodeSettings);
//     window.localStorage.setItem('emailForSignIn', email);
//   } catch (error) {
//     console.error('Error sending sign-in link to email:', error);
//   }
// };

export { app, db, auth };