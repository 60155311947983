import React from 'react';
import newsData from '../../src/newsData.json'; // Import your curated news data

const News = () => {
    return (
        <div className="container">
            <h2>Unieros in the News</h2>
            <p>Here are some news articles about Unieros and its events throughout the years.</p>
            <div className="row">
                <div className="col-md-8 mx-auto">
                    <ul className="list-group text-center"> {/* Added text-center class */}
                        {newsData.map((article, index) => (
                            <li key={index} className="list-group-item mb-3 d-flex justify-content-between align-items-center">
                                <div className="card-body-unieros">
                                    <div>
                                        <img src={article.imageUrl} alt="Featured image" className="card-img-top img-fluid" />
                                    </div>
                                    <div className="card-title">
                                        <h3><a href={article.url} target="_blank" rel="noopener noreferrer">
                                            {article.title}
                                        </a>
                                        </h3>
                                    </div>
                                    <div className="card-text">
                                        <p>{article.description}</p>
                                    </div>
                                    <div className="card-caption">
                                        <p>Published on: {article.publishDate}</p>
                                        <p className="badge badge-primary badge-pill">Source: {article.source}</p>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default News;
