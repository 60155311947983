import React from 'react';
// import Header from '../components/Header';

function Community() {
  const videoId = 'lktyFUyZsjc'; 
 

  return (
    <div className="latest-video my-4">
      <h2 className="text-center display-6 mb-4">Latest Video</h2>
      <div className="embed-responsive embed-responsive-16by9">
        <iframe
          className="embed-responsive-item"
          src={`https://www.youtube.com/embed/${videoId}`}
          title="Latest Video from Unieros community"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

  export default Community;