import React from 'react';
import {createRoot} from 'react-dom/client';
import './index.css';
import App from './App';
import './custom.css';
// import { register } from './serviceWorker';

import { AuthProvider } from "./AuthContext";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

const container =  document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <AuthProvider>

    <App />

    </AuthProvider>
  </React.StrictMode>
 
);


