import React, { useEffect } from 'react';

// import QuestionForm from '../components/QuestionForm';
// import FAQDisplay from '../components/FAQDisplay';



const Contact = () => {
  // const navigate = useNavigate();
  // const [faqs, setFaqs] = useState([]);
  // const { currentUser, sendSignInLinkToEmail } = useAuth();
  // const auth = getAuth();

  // const handleNewQuestion = (email, question) => {
  //   if (!currentUser) {
  //     navigate('/login');
  //     return;
  //   }

    // const actionCodeSettings = {
    //   url: "https://wwww.unieros.com/profile",
    //   handleCodeInApp:true,
    // };
    // sendSignInLinkToEmail(email, actionCodeSettings)
    //   .then( () => {
    //     window.localStorage.setItem("emailForSignIn", email);
    //   })
    //   .catch((error) => {
    //     console.error("Failed to send sign-in link.", error);
    //   });

    //GPT goes here and Firebase
  //   const newFaq = { question, answer: 'Answer coming soon!' };
  //   setFaqs([...faqs, newFaq ]);
  // }

  useEffect(() => {
    document.title = "Unieros - Contact Us";
    // if (isSignInWithEmailLink(auth, window.location.href)) {
    //   let email = window.localStorage.getItem('emailForSignIn');
    //   if (!email) {
    //     email = window.prompt('Please provide your email for confirmation');
    //   }

    //   signInWithEmailLink(auth, email, window.location.href)
    //     .then((result) => {
    //       window.localStorage.removeItem('emailForSignIn');
    //       // User is signed in, you can redirect them now
    //     })
    //     .catch((error) => {
    //       console.error('Error signing in with email link:', error);
    //     });
    // }
  }, []);


  const mailchimpUrl = 'https://us1.list-manage.com/contact-form?u=63f8f6808983a67e8a71f3ae0&form_id=e543ef70ce0e9ea1ec44efba6bd743ac'; 
  
    return (
      <div className="container">
      <h1 className="display-2 page-title">Contact us</h1>
      <div className="container">
      <img src="https://unieros.com/wp/wp-content/uploads/2016/06/brasilFest.jpg" className="img-fluid mb-3" alt="Unieros Brazil Festival meetup in Chicago" />
      </div>
      <p class="lead text-body-secondary ">
       Sign up for our newsletter to receive the latest news, updates, and special offers from our team.</p>
      <div className="text-center">
        <a href={mailchimpUrl} target="_blank" rel="noreferrer" className="btn btn-unieros mb-3">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-envelope-at" viewBox="0 0 16 16">
  <path d="M2 2a2 2 0 0 0-2 2v8.01A2 2 0 0 0 2 14h5.5a.5.5 0 0 0 0-1H2a1 1 0 0 1-.966-.741l5.64-3.471L8 9.583l7-4.2V8.5a.5.5 0 0 0 1 0V4a2 2 0 0 0-2-2H2Zm3.708 6.208L1 11.105V5.383l4.708 2.825ZM1 4.217V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v.217l-7 4.2-7-4.2Z"/>
  <path d="M14.247 14.269c1.01 0 1.587-.857 1.587-2.025v-.21C15.834 10.43 14.64 9 12.52 9h-.035C10.42 9 9 10.36 9 12.432v.214C9 14.82 10.438 16 12.358 16h.044c.594 0 1.018-.074 1.237-.175v-.73c-.245.11-.673.18-1.18.18h-.044c-1.334 0-2.571-.788-2.571-2.655v-.157c0-1.657 1.058-2.724 2.64-2.724h.04c1.535 0 2.484 1.05 2.484 2.326v.118c0 .975-.324 1.39-.639 1.39-.232 0-.41-.148-.41-.42v-2.19h-.906v.569h-.03c-.084-.298-.368-.63-.954-.63-.778 0-1.259.555-1.259 1.4v.528c0 .892.49 1.434 1.26 1.434.471 0 .896-.227 1.014-.643h.043c.118.42.617.648 1.12.648Zm-2.453-1.588v-.227c0-.546.227-.791.573-.791.297 0 .572.192.572.708v.367c0 .573-.253.744-.564.744-.354 0-.581-.215-.581-.8Z"/>
</svg> Sign up for news
        </a>
      </div>
      {/* <div>
      <h2 className="display-6">FAQs</h2>
      <p>Let us know any questions about Unieros and our automated service will do its best to answer your question.</p>
    
    
    </div> */}
    </div>
    
    )
}

  export default Contact;