import React from 'react';

const Testimonials = () => {
  const testimonialsData = [
    {
      name: 'Jose',
      testimonial:
        'The people are very nice. I’ve met people from all over the world.',
    },
    {
      name: 'Chicago Sun-Times',
      testimonial:
        'Unieros leverages the latest technologies to ensure that the cultural dialog grows richer',
    },
    {
      name: '',
      testimonial:
        'I love Unieros events! They are always well-organized and full of interesting activities.',
    },
  ];

  return (
    <div className="testimonials">
      <h2 className="text-center mb-4">What People Are Saying</h2>
      <div className="row">
        {testimonialsData.map((testimonial, index) => (
          <div key={index} className="col-md-4">
            <div className="testimonial">
              <div className="card-body">
                <p className="card-text testimonial-content">{testimonial.testimonial}</p>
                <h5 className="card-title testimonial-author">{testimonial.name || 'Anonymous'}</h5>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
