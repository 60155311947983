import React from 'react';
import { Link } from 'react-router-dom';
import logo from '../logo.svg';
import '../custom.css';import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookF, faYoutube, faTwitter, faLinkedinIn, faYoutubeSquare } from '@fortawesome/free-brands-svg-icons';


const Header = () => {
    return (
        <div>
        <header className="header">
             <nav className="navbar navbar-expand-lg">
        <Link className="navbar-brand m-2" to="/"><img src={logo} className="Unieros-logo" alt="logo" /></Link>
       

        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ml-auto social-icons">
          <li className="nav-item">
      <a className="nav-link" href="https://www.facebook.com/unieros" target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={faFacebookF} size="1x" />
      </a>
    </li>
    <li className="nav-item">
      <a className="nav-link" href="https://www.instagram.com/unieros" target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={faInstagram} size="1x" />
      </a>
    </li>
    <li className="nav-item">
      <a className="nav-link" href="https://www.linkedin.com/company/unieros" target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={faLinkedinIn} size="1x" />
      </a>
    </li>
    <li className="nav-item">
      <a className="nav-link" href="https://www.youtube.com/unieros" target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={faYoutube} size="1x" />
      </a>
    </li>
            <li className="nav-item">
              <Link className="nav-link" to="/">Home</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/events">Events</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/about">About us</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/news">News</Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/blog">Blog</Link>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link" to="/login">Login</Link>
            </li> */}
            <li className="nav-item">
              <Link className="nav-link" to="/partnerships">Partnerships</Link>
            </li>
            
            <li className="nav-item">
              <Link className="nav-link" to="/contact">Contact</Link>
            </li>
           
          </ul>
        </div>
      </nav>
        </header>
        </div>
    );
};

export default Header;