import React, { useEffect, useState } from 'react';
import axios from 'axios';

const FlagBanner = () => {
  const [flags, setFlags] = useState([]);

  useEffect(() => {
    const fetchFlags = async () => {
      try {
        // Fetch the flag data
        const response = await axios.get('https://flagcdn.com/en/codes.json');
        const flagData = response.data;

        // Get a random set of 10 flags
        const flagKeys = Object.keys(flagData);
        const randomFlags = ['jam', 'bh', 'gy', 'tr', 'us', 'bh'];
        for (let i = 0; i < randomFlags.length; i++) {
          const randomIndex = Math.floor(Math.random() * flagKeys.length);
          const countryCode = flagKeys[randomIndex].toLowerCase();
          const flagUrl = `https://flagcdn.com/40x30/${countryCode}.png`;
          randomFlags[i] = flagUrl;

          // Remove the used flag to avoid duplicates
          flagKeys.splice(randomIndex, 1);
        }

        setFlags(randomFlags);
      } catch (error) {
        console.error('Error fetching flags:', error);
      }
    };

    fetchFlags();
  }, []);

  return (
    <div className="flag-banner d-flex flex-row justify-content-center flex-wrap w-100">
      {flags.map((flag, index) => (
        <img
          key={index}
          src={flag}
          alt={`Flag ${index + 1}`}
          className="flag-icon mr-1"
         
        />
      ))}
    </div>
  );
};

export default FlagBanner;
